@use 'shared/styles/variables';

.containerBlockHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
  }
}

.containerBlockSubtitle {
  font-size: 16px;
  line-height: 24px;
  opacity: 0.6;
  margin: 0;
}

.slidersContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 16px;
}

.resultsCompositeBlocks {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 16px;
  gap: 24px;
  & > div {
    background-color: rgba(255, 255, 255, 0.02);
    padding: 12px 16px;
    & > h2 {
      font-size: 16px;
      line-height: 24px;
      height: 48px;
      margin-bottom: 4px;
    }
    p {
      font-family: 'Bower';
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    span {
      font-family: 'MckinseySans-Light';
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
    }
  }
}

.relativeChangeBlock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.relativeChangeWrapper {
  padding: 6px 8px;
  display: flex;
  align-items: center;
}

.moduleUnderDevelopment {
  font-size: 32px;
  text-align: center;
  margin: 48px 0;
}

.downloadBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.bcktBtn {
  display: flex;
  background: none;
  border: none;
  font-size: 16px;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  color: var(--white-color);
  text-decoration: none;
  font-family: 'MckinseySans-Medium';
  line-height: 24px;

  & .icon {
    color: var(--deep-sky-blue);
    transition: all ease 0.2s;
  }

  &:hover {
    text-decoration: underline;

    & .icon {
      margin-left: 2px;
    }
  }
}

.trackers {
  overflow-x: auto;
}

.trackerContainer {
  display: flex;
  flex-direction: column;
  margin: variables.$app-content-padding 0;
}

.dropdownContainer {
  margin-left: 20px;
  align-self: center;
}

.wrapper {
  width: 100%;
  min-width: 1000px;
  background-color: var(--surface-color);
  box-shadow: 0 0 20px 4px var(--shadow-color);
  z-index: 1;
}

.header {
  width: 100%;
  border-bottom: 1px solid var(--border-color);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 16px;
  padding: 11px 0 15px 10px;
  font-weight: bold;
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
}

.legendItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
}

.legendLabel {
  font-size: 12px;
  margin-left: 10px;
}

.actual {
  width: 14px;
  height: 2px;
  background-color: var(--actual-color);
}

.optimized {
  width: 14px;
  height: 2px;
  background-color: var(--optimized-color);
}

.baseline {
  width: 14px;
  height: 0;
  border-top: 2px dotted var(--baseline-color);
}

.content {
  height: auto;
  margin: 12px;
}

.row {
  height: 350px;
  margin-top: 10px;
  padding-top: 25px;
  border-top: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
}

.timeseriesPlotContainer {
  height: 300px;
  margin-bottom: 25px;
}

.timeseriesPlotContainerHigher {
  height: 800px;
  margin-bottom: 25px;
}

.timeseriesPlotContainerMidSize {
  height: 650px;
  margin-bottom: 25px;
}

.rawHtmlPlotContainer {
  height: 800px;
  margin-bottom: 25px;
}

.distPlotContainer {
  width: calc(50% - 20px);
  margin: 0 10px;
}

.rc-slider-disabled {
  background-color: #0097a7;
}

.stickyHeader {
  font-weight: 'bold';
  position: 'sticky';
  top: 0;
  margin: 0;
  margin-bottom: 10;
}

.primary {
  background-color: rgba(34, 81, 255, 0.8);
  font-size: 16px;
  color: white;
  padding: 8px;
  border-radius: 5px;
  border: 0px;
  margin-right: 40px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  font-family: TitilliumWeb, sans-serif;
}

.primary:hover {
  background-color: rgba(34, 81, 255, 1);
}

.primary:disabled {
  background-color: rgba(255, 255, 255, 0.4);
  color: rgb(50, 50, 50);
}

.secondary {
  background-color: rgb(6, 35, 55);
  font-size: 16px;
  color: white;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-right: 40px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  font-family: TitilliumWeb, sans-serif;
}

.secondary:hover {
  color: rgba(34, 81, 255, 1);
  border: 1px solid rgba(34, 81, 255, 1);
}

.secondary:disabled {
  background-color: rgba(255, 255, 255, 0.4);
  color: rgb(50, 50, 50);
}
