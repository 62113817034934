.wrapper {
  display: flex;
  gap: 24px;
  align-items: center;
}

.label {
  font-size: 16px;
  line-height: 24px;
}

.btn {
  border: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-color);
  padding: 2px 4px;

  &:hover {
    cursor: pointer;
    background-color: var(--primary-color);
  }

  &.active {
    border-bottom: 2px solid var(--secondary-color);
  }
}
