.container,
.btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  position: relative;
  margin: 8px 0;
  color: var(--icon-color);
  border-radius: 4px;
}

.container:hover,
.btn:hover,
.activeLink {
  color: var(--text-color);
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);

  .icon {
    & > div {
      border-color: var(--secondary-color);
    }

    svg {
      color: var(--secondary-color);
    }
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
}

.icon {
  display: flex;
  width: 24px;

  svg {
    color: var(--icon-color);
  }
}

.separator {
  height: 1px;
  background-color: var(--border-color);
  width: 80%;
}

.label {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
}

.defaultIcon {
  width: 22px;
  height: 22px;
  border: 2px solid var(--icon-color);
  border-radius: 100%;
}

.btn {
  background-color: transparent;
  border: none;
  color: #fff;
  padding: 0;
  margin: 0;
}
