.primary {
  background-color: rgba(34, 81, 255, 0.8);
  font-size: 16px;
  color: white;
  padding: 8px;
  border-radius: 5px;
  border: 0px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  font-family: TitilliumWeb, sans-serif;
}

.primary:hover {
  background-color: rgba(34, 81, 255, 1);
}

.primary:disabled {
  background-color: rgba(255, 255, 255, 0.4);
  color: rgb(50, 50, 50);
}
