.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
  right: 0;
  z-index: 1000000;
  margin-top: 35px;
}

.toast {
  color: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 9px -1px rgba(0, 21, 33);
  background-color: #009df9;
  border-radius: 2px;
  width: 350px;
  word-wrap: break-word;
  margin: 15px 10px;
  text-align: left;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 12px 15px 15px;

  $icon-size: 20px;

  .timestamp {
    margin-left: 5px;
    opacity: 0.8;
    font-size: 14px;
    white-space: nowrap;
  }
}

.error {
  background-color: #d13913 !important;
  color: white !important;
  box-shadow: 0 0 9px -1px #d13913 !important;
}

.appearEffect {
  animation: appear 0.2s;
}

.disappearEffect {
  animation: disappear 0.3s !important;
  overflow-y: hidden;
  max-height: 0;
}

@keyframes disappear {
  0% {
    max-height: 400px;
    visibility: visible;
  }
  100% {
    max-height: 0;
    visibility: hidden;
  }
}

@keyframes appear {
  from {
    margin-right: -350px;
    margin-left: 0;
  }
  60% {
    margin-right: 0;
    margin-left: -50px;
  }
  to {
    margin-right: 0;
    margin-left: 0;
  }
}
