.swagger-ui {
  filter: invert(88%) hue-rotate(180deg);

  .highlight-code {
    filter: invert(100%) hue-rotate(180deg);
  }

  .wrapper {
    margin: 0;
    max-width: 100%;
  }
}
