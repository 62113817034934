@use 'shared/styles/animations';

.container {
  position: relative;
  width: fit-content;
}

%arrow {
  content: ' ';
  position: absolute;
  left: 50%;
  margin-left: -4px;
  border-width: 4px;
  border-style: solid;
  border-color: var(--primary-dark-color) transparent transparent transparent;
}

%tooltip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 12px;
  background: var(--primary-dark-color);
  word-break: break-word;
  white-space: nowrap;
  padding: 2px 7px;
  font-weight: normal !important;
  z-index: 2;

  animation-name: fadeIn;
  animation-duration: 900ms;
}

.topTooltip {
  @extend %tooltip;
  bottom: 100%;
  margin-bottom: 3px;

  &::after {
    @extend %arrow;
    top: 100%;
    border-color: var(--primary-dark-color) transparent transparent transparent;
  }
}

.bottomTooltip {
  @extend %tooltip;
  top: 100%;
  margin-top: 3px;

  &::before {
    @extend %arrow;
    bottom: 100%;
    border-color: transparent transparent var(--primary-dark-color) transparent;
  }
}
