.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.navBar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.linkList {
  width: 100%;
}
