.icon {
  display: flex;
  align-items: center;
  gap: 8px;
  g {
    fill: var(--icon-color) !important;
  }
}

.content {
  min-width: 180px;
  padding: 5px 15px;
}

.title {
  font-weight: bold;
  padding: 10px 0;
}

.item {
  padding: 5px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
