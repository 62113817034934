.wrapper {
  display: flex;
  gap: 24px;
  margin-top: 24px;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & p {
    font-size: 14px;
  }

  & span {
    display: inline-block;
    width: 51px;
    height: 12px;
  }
}
