@use 'shared/styles/variables.scss';

$input-height: 40px;

.picker {
  --rdp-cell-size: 24px;
  margin: 0;
}

.table {
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  thead {
    th {
      font-size: 12px;
      padding-bottom: 10px;
    }
  }
}

.container {
  position: relative;
  min-width: 180px;
}

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $input-height;
  line-height: $input-height;
  padding-left: 12px;
  padding-right: 10px;
  font-size: 14px;
  background-color: var(--arsenic);

  &:hover {
    background-color: var(--arsenic);
  }
}

.inputHighlighted {
  background-color: var(--arsenic);
}

.datePicker {
  display: none;
  position: absolute;
  z-index: 100;
  width: 170px;
  box-shadow: variables.$shadow;
  background-color: var(--arsenic);
  padding: 17px 12px 15px;
}

.datePickerVisible {
  display: block;
}

.datePickerWrapper {
  position: relative;
  font-size: 12px;
  outline: none;
}

.months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.month {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.navBar {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 0 10px;
}

.navButton {
  top: -2px;
  z-index: 100;

  svg {
    fill: var(--secondary-color) !important;
  }
}

.navButtonPrev {
  left: 13px;
  transform: rotate(-90deg);
}

.navButtonNext {
  right: 13px;
  transform: rotate(90deg);
}

.navButtonInteractionDisabled {
  display: none;
}

.caption {
  text-align: center;
}

.weekdays {
  display: flex;
  margin-top: 11px;
  margin-bottom: 17px;
}

.weekdaysRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.weekday,
.day {
  flex: 1;
  width: 100%;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
}

.weekday abbr[title] {
  text-decoration: none;
}

.week {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.day {
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;

  &[aria-disabled='true'] {
    pointer-events: none;
    background-color: transparent !important;
    border: none !important;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    border: 1px solid var(--secondary-color);
    background-color: transparent !important;
    color: inherit !important;
  }
}

.disabled {
  pointer-events: none;
  opacity: 1 !important;
  color: var(--text-secondary-color);
}

.selected {
  background-color: var(--secondary-color) !important;
  color: white !important;
  outline: none;
}

.dayToday {
  font-weight: normal;
}

.selectedRange {
  background-color: var(--selection-color);
}

.highlighted {
  border: 1px solid var(--secondary-color);
}

.rangeMiddleSelection {
  background: var(--selection-color) !important;
}

.inputIcon {
  display: flex;
  align-items: center;
}

.calendarIcon {
  color: var(--secondary-color);
  width: 18px;
  cursor: pointer;
}
