.wrapper {
  position: relative;
}

.scroller {
  overflow-x: auto;
}

.header {
  margin-top: 12px;
  border-bottom: 1px solid var(--border-color);
}
