.darkTheme {
  // ===== Colors =====
  --primary-color: #06263b;
  --primary-dark-color: rgb(0, 21, 33);
  --secondary-color: #009df9;

  --white-color: #fff;
  --dark-color: #081c28;
  --orange-color: #ffa800;
  --green-color: #26c022;
  --blue-color: #164559;
  --red-color: #ff0000;
  --yellow-color: #e4ac00;
  --gray-color: #90999e;
  --faded-gray-color: rgba(77, 77, 77, 0.8);
  --cerulean-blue: #3949ab;
  --neon-blue: #2251ff;

  // ====== Variables =====

  // backgrounds
  --surface-color: var(--primary-color);
  --secondary-surface-color: #184561;
  --light-surface-color: #082644;
  --background-color: #051c2c;
  --background-color-rgb: 5, 28, 44;
  --black-pearl: #041623;

  // text
  --text-color: rgba(255, 255, 255, 0.85);
  --text-secondary-color: #74818c;

  // ui elements
  --arsenic: #39444f;
  --border-color: #223c47;
  --selection-color: rgba(0, 157, 249, 0.25);
  --hover-color: rgba(var(--background-color-rgb), 0.7);
  --icon-color: var(--text-color);
  --shadow-color: rgba(0, 21, 33);
  --deep-sky-blue: #00a9f4;

  // features
  --optimized-color: var(--white-color);
  --baseline-color: rgba(255, 255, 255, 0.4);
  --actual-color: var(--secondary-color);

  --approve-color: var(--green-color);
  --decline-color: var(--red-color);
  --pending-color: var(--gray-color);

  color: var(--text-color);
}
