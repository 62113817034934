@use 'shared/styles/variables';

.container {
  position: relative;
  height: 100%;
  display: flex;
}

.button {
  height: 100%;
  padding: 2px 0 6px;
  margin-top: -8px;
}

.tooltip {
  height: inherit;
}

.childrenWrapper {
  position: absolute;
  z-index: 2;
  right: 0;
  background-color: var(--surface-color);
  box-shadow: variables.$shadow;
  top: 100%;
}

.childrenWrapperAlignLeft {
  right: auto;
  left: 0;
}

.childrenWrapperAlignTop {
  bottom: 100%;
  top: auto;
}
