.fieldset {
  padding-right: 12px;
  border: 0;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.line {
  height: 20px;
  padding: 8px 0;
  display: flex;
  align-items: flex-start;
}

.label {
  user-select: none;
  padding: 8px 0px;
  margin: 0 0 16px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.textIpt {
  color: #fff;
  border: 0;
  padding: 8px;
  height: 20px;
  width: 100px;
  background-color: rgb(24, 24, 24);
  user-select: none;

  &:hover {
    outline: 1px solid rgb(34, 81, 255);
  }
}
