@use 'variables';

@font-face {
  font-family: 'MckinseySans';
  src: local('MckinseySans'),
    url('../assets/fonts/McKinseySans-Regular.woff') format('woff'),
    url('../assets/fonts/McKinseySans-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'MckinseySans-Light';
  src: local('MckinseySans-Light'),
    url('../assets/fonts/McKinseySans-Light.woff') format('woff'),
    url('../assets/fonts/McKinseySans-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'MckinseySans-Medium';
  src: local('MckinseySans-Medium'),
    url('../assets/fonts/McKinseySans-Medium.woff') format('woff'),
    url('../assets/fonts/McKinseySans-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Bower';
  src: local(Bower-Bold), url('../assets/fonts/BowerBold.woff2') format('woff2'),
    url('../assets/fonts/BowerBold.woff') format('woff'),
    url('../assets/fonts/BowerBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

html,
body {
  margin: 0;
  font-family: 'MckinseySans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

textarea::placeholder {
  font-size: 12px;
}

textarea {
  padding: 4px;
  font-size: 12px;
}

input,
textarea {
  font-family: TitilliumWeb, sans-serif;
  color: var(--text-color);
}

input::placeholder,
textarea::placeholder {
  font-family: TitilliumWeb, sans-serif;
  color: var(--text-secondary-color);
}

body > div > div {
  /* Firefox scrollbar */
  scrollbar-color: var(--border-color) var(--surface-color);
  /* IE scrollbar */
  scrollbar-face-color: #0064a7;
  scrollbar-shadow-color: #8ea5b5;
  scrollbar-highlight-color: #8ea5b5;
}

/* Chrome, Safari, Opera scrollbar */

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar:horizontal {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

::-webkit-scrollbar:vertical {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: var(--border-color);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb:hover {
  opacity: 0.3;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
