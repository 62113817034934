.button {
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  padding: 8px;
  font-family: TitilliumWeb, sans-serif;
  transition: all ease 0.2s;

  &:hover {
    background-color: var(--secondary-color);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.55;
  }
}

.contained {
  background-color: var(--neon-blue);
  color: var(--text-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.text {
  background-color: transparent;
  color: var(--secondary-color);
}

.outlined {
  background-color: transparent;
  border: 2px solid var(--text-color);
  color: var(--text-color);
}

.none {
  background-color: transparent;
  color: inherit;
}
