@use 'shared/styles/variables';

.downloadBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin: 12px 0;
}

.containerBlockHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
  }
}

.containerBlockSubtitle {
  font-size: 16px;
  line-height: 24px;
  opacity: 0.6;
  margin: 0;
}

.forecastPlotWrapper {
  flex: 8;
  height: '100%';
  position: 'relative';
}

.btnOptions {
  margin: 24px 0;
  display: flex;
  gap: 32px;
}
// -----------------
.trackers {
  overflow-x: auto;
}

.trackerContainer {
  display: flex;
  margin: variables.$app-content-padding 0;
}

.dropdownContainer {
  align-self: center;
}

.wrapper {
  width: 100%;
  min-width: 1000px;
  background-color: var(--surface-color);
  box-shadow: 0 0 20px 4px var(--shadow-color);
  z-index: 1;
}

.header {
  width: 100%;
  border-bottom: 1px solid var(--border-color);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 16px;
  padding: 11px 0 15px 10px;
  font-weight: bold;
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
}

.legendItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
}

.legendLabel {
  font-size: 12px;
  margin-left: 10px;
}

.actual {
  width: 14px;
  height: 2px;
  background-color: var(--actual-color);
}

.optimized {
  width: 14px;
  height: 2px;
  background-color: var(--optimized-color);
}

.baseline {
  width: 14px;
  height: 0;
  border-top: 2px dotted var(--baseline-color);
}

.row {
  height: 350px;
  margin-top: 10px;
  padding-top: 25px;
  border-top: 1px solid var(--border-color);
  display: flex;
  flex-direction: row;
}

.timeseriesPlotContainer {
  height: 300px;
  margin-bottom: 25px;
}

.timeseriesPlotContainerHigher {
  height: 800px;
  margin-bottom: 25px;
}

.timeseriesPlotContainerMidSize {
  height: 650px;
  margin-bottom: 25px;
}

.rawHtmlPlotContainer {
  height: 800px;
  margin-bottom: 25px;
}

.distPlotContainer {
  width: calc(50% - 20px);
  margin: 0 10px;
}

.rc-slider-disabled {
  background-color: #0097a7;
}

.selected {
  background-color: var(--hover-color);
}
