$animation-time: 0.2s;
$box-unchecked-color: #b3b3b3;
$box-checked-color: var(--secondary-color);
$checkmark-color: var(--secondary-color);

$checkbox-size: 20px;
$label-margin: 6px;

.container {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  align-items: center;
  width: fit-content;
  min-width: $checkbox-size;
  min-height: $checkbox-size;

  &:hover .input ~ .checkbox {
    background-color: var(--hover-color);
  }

  &:hover .input:not(:checked) ~ .checkbox {
    opacity: 0.7;
  }
}

.label {
  padding-left: $checkbox-size + $label-margin;
}

// box
.checkbox {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  height: $checkbox-size;
  width: $checkbox-size;
  box-sizing: border-box;
  border: 1px solid $box-unchecked-color;
  transition: border ease-in-out $animation-time;

  // checkmark
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid $checkmark-color;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    animation: expand $animation-time ease-in-out;
  }
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ .checkbox {
    border: 1px solid $box-checked-color;
  }

  &:checked ~ .checkbox:after {
    display: block;
  }
}

.disabled {
  color: var(--text-secondary-color) !important;
  cursor: not-allowed !important;
  opacity: 0.5;
}

@keyframes expand {
  from {
    transform: scale(0) rotate(45deg);
  }
  to {
    transform: scale(1) rotate(45deg);
  }
}
