@use 'shared/styles/animations';

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: max-content;
  height: max-content;
}

.contentContainer {
  display: inherit;
  width: inherit;
  height: inherit;
}

.noContentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 100%;
}
