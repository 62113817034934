.wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  color: var(--white-color);
  text-decoration: none;
  font-family: 'MckinseySans-Medium';
  line-height: 24px;

  & .icon {
    color: var(--deep-sky-blue);
    transition: all ease 0.2s;
  }

  &:hover {
    text-decoration: underline;

    & .icon {
      margin-left: 2px;
    }
  }
}
