@use 'shared/styles/variables';

.container {
  position: relative;
  border: none;
  background-color: var(--arsenic);
  cursor: pointer;
  min-width: 100px;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-size: 14px;

  &:hover {
    background-color: var(--arsenic);
  }
}

.highlighted {
  background-color: var(--arsenic);
}

.label {
  font-size: 12px;
  color: var(--text-secondary-color);
}

.selectedValue {
  margin: 0 10px;
  width: 150px;
}

.expandIcon {
  padding: 0 5px;
  width: 10px;
  transition: transform 200ms;
  transform: rotate(0deg);

  path {
    fill: var(--white-color);
  }
}

.collapseIcon {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  right: 0;
  z-index: 100;
  width: 100%;
  box-shadow: variables.$shadow;
  background-color: var(--arsenic);
  list-style-type: none;
  margin: 0;
  padding: 8px 0;
  font-size: 14px;
  box-sizing: border-box;
}

.option {
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: var(--hover-color);
  }
}
