.wrapper {
  width: 100%;
  margin-top: 24px;
  background-color: var(--white-color);

  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }

    70% {
      opacity: 0.2;
    }

    100% {
      opacity: 0.5;
    }
  }
}
