.title {
  max-width: 674px;
  font-size: 18px;
  line-height: 28px;
  opacity: 0.7;
  font-family: 'MckinseySans-Light';
  font-weight: 300;
  margin-bottom: 16px;

  & > b {
    font-family: 'MckinseySans';
    font-weight: bold;
  }
}
