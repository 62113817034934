.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: var(--light-surface-color);
  width: 184px;
  padding: 40px 16px 30px;
  box-sizing: border-box;
}

.logoWrapper {
  display: flex;
  align-items: 'center';
  gap: 8px;
}

.logo {
  margin-bottom: 30px;
}

.altLogo {
  width: 105px;
  margin-bottom: 0px;
}

.userLogo {
  max-width: 55px;
}

.buttonsContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 89%;
}
