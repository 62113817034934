.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
  align-items: flex-end;
  box-sizing: border-box;
  min-height: 72px;
  margin-bottom: 24px;
}

.title {
  font-family: 'Bower';
  font-weight: 700;
  font-size: 44px;
  color: #fff;
  margin: 0;
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
