.wrapper {
  width: 100%;
  height: 100%;
}

.container {
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.heroImg {
  height: 80%;
  margin-top: 1.25rem;
}

.disclaimer {
  font-size: 14px;
  font-style: italic;
}
